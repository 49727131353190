@import 'primeflex/primeflex.scss';
@import './icons/style.css';

html {
  font-size: 14px;
  overflow: hidden;
}

.App {
  @include styleclass('flex flex-column');
  height: 100vh;
  width: 100vw;
  background: #f2f2f9;


  .main {
    @include styleclass('flex flex-grow-1 p-3');

    .sidebar {
      @include styleclass('flex pr-3');

      >div {
        @include styleclass('flex-grow-1');
      }
    }

    .content {
      @include styleclass('flex flex-grow-1');

      >div {
        @include styleclass('flex-grow-1');
      }
    }
  }
}

.no-padding {
  >div.p-card-body {
    @include styleclass('p-0');
  }
}

.sidebar{
  .p-menu{
    margin-top:0;
    padding-top:0;
    .p-menu-list{
      li:first-child{
        margin-bottom:5px
      }
    }
  }
}
.sidebar-portal-dropdown {
  .p-card-content {
    padding: 0;
  }

  .p-dropdown-label {
    padding: 7px;
    max-width: 150px;
  }

  ul.p-menu-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}

.fullHeightCard {
  display: flex;
  flex-direction: column;

  .p-card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 0;

    .p-card-content {
      flex-grow: 1;
      height: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }
}

.noPaddingCard {
  .p-card-body {
    @include styleclass('p-0');
  }
}

.noPaddingContentCard {
  .p-card-body {
    .p-card-content {
      @include styleclass('p-0');
    }
  }
}

.overflowYContentCard {
  .p-card-body {
    .p-card-content {
      overflow-y: auto;
    }
  }
}

.overflowY {
  overflow-y: auto;
}


.fullHeightTable {}

.globalSpinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);

  .spinner {}
}

.readOnlyContent{
  img{
    max-width: 100%;
  }
}

.Editor {
  @include styleclass('flex flex-column flex-grow-1');
  border-right: 1px solid rgb(230, 230, 230);
  .EditorMenuBar+div{
    position: relative;
  }
  .EditorMenuBar {
    @include styleclass('p-2');
    border-bottom: 1px solid rgb(230, 230, 230);

    .p-buttonset{
      margin:0 5px;
      display: inline-block;
    }

    &+div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .tiptap {
        min-height: 300px;
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        border: none;
        outline: 0px solid transparent;
        @include styleclass('p-2');
      }
    }
  }
}

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }

    ul li,
    ol li {
    	display: list-item;
    }
    
    ul[data-type="taskList"] > li {
    	display: flex;
    }
  }
}

.blockType {
  .p-dropdown-label {
    padding: 5px;
  }
}

.stepSideBar {
  width: 500px;
}

.formSideBar {
  width: 500px;
}

.tiptap .is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap {
  iframe {
    width: 100%;
    height: 300px;
  }

  pre {

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      >* {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.tiptap {
  >*+* {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

.overflow-y {
  overflow-y: auto;
}

.p-tabview.fullHeight {
  display: flex;
  flex-direction: column;

  .p-tabview-panels {
    flex-grow: 1;
    display: flex;

    .p-tabview-panel {
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }
}

.separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
  display: block;
}

